<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" >暂无</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="80">
            </el-table-column>
            <el-table-column
                prop="title"
                label="标题">
            </el-table-column>
            <el-table-column
                prop="businessId"
                label="业务主键"
                width="80">
            </el-table-column>
            <el-table-column
                prop="code"
                label="编号"
                width="120">
            </el-table-column>
            <el-table-column
                prop="status"
                label="状态"
                width="80">
            </el-table-column>
            <el-table-column
                prop="updateUserId"
                label="修改人"
                width="80">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="修改时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                    <el-button type="text" @click="handleView(scope.row)">详情</el-button>
                    <el-button type="text" @click="handleMarge(scope.row)" :disabled="scope.row.status !== 3">重试</el-button>
                    <el-button type="text" @click="handleDowmload(scope.row)" :disabled="scope.row.status !== 2">下载</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog title="章节" :visible.sync='open' width='80%' :before-close='cancel'>
      <br id="section1">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>{{ detail.title }}</span>
        </div>
        <div>
          <div v-for="(item,index) in detail.newContent" :key="index">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ '文章：' + item.sort }}</span>
              </div>
              <div>{{ item.content }}</div>
            </el-card>
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>图片</span>
              </div>
              <img v-for="(url, inp) in item.url" :src="url" :key="inp" width="30%" alt="暂无图片" style="margin-left: 5px;">
            </el-card>
          </div>
        </div>
      </el-card>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { videoComposePage, margeVideoByContentId, videoComposeDownload } from '@/service/headlines';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 是否显示弹出层
      open: false,
      detail: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      videoComposePage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleView(row){
      let newContent = JSON.parse(row.content);
      this.detail = {
        ...row,
        newContent
      };
      this.open = true;
    },
    cancel(){
      this.open = false;
      this.detail = {};
    },
    handleMarge(row){
      margeVideoByContentId({id:row.businessId, code:row.code}).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    handleDowmload(row){
      videoComposeDownload({id:row.id}).then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data], {type: `video;`}));
        const a = document.createElement("a");
        a.href = url;
        a.download = row.title + '.mp4';
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        document.body.removeChild(a);
        this.getList();
      });
    }
  }
};
</script>